.flexHeader{
    position: absolute;

    list-style: none;

    margin: 1vw;

    /* top: 39%!important;
    left: 40%!important; */
    z-index: 5;
}

/************
*   LOGO    *
*************/
.flexHeader .logo{
    height: 10vw;
    width: 88%;
    z-index: 4;
    margin-left: 0.3vw;
    /* margin-left: 4.5vw; */

    display: flex;
    justify-content: center;

    /* background-color: red; */
}

.flexHeader .logo svg{
    height: 100%;
}

.flexHeader .grabDiv{
    position: absolute;
    height: 5vw;
    width: 88%;
    z-index: 5;
    margin-left: 0.3vw;
    /* background-color: red; */
}
/************
*   Menu    *
*************/
.flexHeader .menu{
    position: absolute;
    width: 20vw;
    height: auto;

}
.flexHeader .menuContent{
    /* position: absolute; */

    width: 15.5vw;
    margin-left: 0.3vw;
    margin-top: 1vh;

    padding: 1vw;
    
    backdrop-filter: blur(10px) saturate(70%);

    border-color: black;
    border-style: solid;
    border-width: 2px;


    /* box-shadow: 0 0 15px 6px yellow; */

    display: none;



}

.flexHeader .menu:hover .menuContent {display: block;}


.flexHeader .menuContent h1{
    color: black!important;
    font-size: var(--font-menu-size-h1)!important;
}
.flexHeader .menuContent h1:hover{
    color: red!important;
    font-size: var(--font-menu-size-h1)!important;
}
.flexHeader .menuContent h2{
    color: black!important;

    font-size: var(--font-menu-size-h1)!important;
    /* font-family: var(--font-h-family-h1); */
    font-weight: 400;
}
.flexHeader .menuContent li{
    color: black!important;
    font-size: var(--font-menu-size-li)!important;
}
.flexHeader .menuContent li:hover{
    /* color: red!important; */
    font-size: var(--font-menu-size-li)!important;
}



.flexHeader .menu__active{
    color: white!important;
    border-color: white!important;
}

.flexHeader .menu__active h1{
    color: white!important;
}

.flexHeader .menu__active h2{
    color: white!important;
}

.flexHeader .menu__active li{
    color: white!important;
}

.flexHeader .menu__active  a{
    color: white!important;
}



.flexHeader .active .cls-1 {
    fill: white!important;
}

.flexHeader .active .cls-2 {
    fill: none;
    stroke: white!important;
}

@media only screen and (max-width: 450px) {

    /************
    *   LOGO    *
    *************/
    .flexHeader .logo{
        height: 10vw;
        width: 7.5vw;
        z-index: 4;

    }
    .flexHeader .logo svg{
        height: 100%;
    }

    /************
    *   Menu    *
    *************/
    .menu{
        position: absolute;

        width: 40vw;

    }
    .flexHeader .menuContent{
        width: 88vw;
        margin-left: 2vw;
        margin-top: 2vh;

        padding: 3vw;
        background-color: yellow;

        box-shadow: 0 0 15px 6px yellow;

        display: none;

    }

    .flexHeader .menuContent h1{
        font-size: 8vw!important;
    }
    .flexHeader .menuContent h2{
        font-size:7vw!important;
        font-weight: 200;
    }
    .flexHeader .menuContent li{
        font-size: 5vw!important;
    }
    .flexHeader .menuContent h1:hover{
        font-size: 8vw!important;
    }




    /***************************
    *       displayNone        *
    ****************************/
    .menuButtons{
        display: none;
    }
}