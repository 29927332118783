@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Faster+One&family=Fredoka:wght@400;500;700&family=Ibarra+Real+Nova:ital,wght@0,400;0,700;1,400;1,700&family=Macondo+Swash+Caps&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Potta+One&family=Press+Start+2P&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Beastly&family=Rubik+Bubbles&family=Rubik+Burned&family=Rubik+Glitch&family=Rubik+Iso&family=Rubik+Mono+One&family=Rubik+Puddles&family=Rubik+Spray+Paint&family=Rubik+Vinyl&family=Rubik:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Titan+One&family=UnifrakturMaguntia&display=swap');
@import url('https://use.typekit.net/qxs4ahm.css');
@import url("https://use.typekit.net/qxs4ahm.css");

@import './assets/styles/components/Header.css';
@import './assets/styles/components/HeaderHome.css';
@import './assets/styles/components/FlexHeader.css';
@import './assets/styles/components/Main.css';


@import './assets/styles/pages/Homepage.css';
@import './assets/styles/pages/Contributerspage.css';
@import './assets/styles/pages/Insituspage.css';
@import './assets/styles/pages/SingleInsitupage.css';
@import './assets/styles/pages/Aboutpage.css';
@import './assets/styles/pages/Archivepage.css';
@import './assets/styles/pages/Agendapage.css';
@import './assets/styles/pages/Entrancepage.css';

@import './assets/styles/var.css';
@import './assets/fonts/stylesheet.css';

@import './assets//styles/pages/articles/Articles.css';
@import './assets//styles/pages/articles/Montage.css';
@import './assets//styles/pages/articles/Transition.css';
@import './assets//styles/pages/articles/PostHuman.css';
@import './assets//styles/pages/articles/TwoTowers.css';
@import './assets//styles/pages/articles/Woodblocks.css';
@import './assets//styles/pages/articles/Nan.css';


@import './assets/styles/pages/Toolkitweek.css';

/* 
GOOGLE
  font-family: 'Cormorant', serif;
  font-family: 'EB Garamond', serif;
  font-family: 'Faster One', cursive;
  font-family: 'Fredoka', sans-serif;
  font-family: 'Ibarra Real Nova', serif;
  font-family: 'Macondo Swash Caps', cursive;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Potta One', cursive;
  font-family: 'Press Start 2P', cursive;
  font-family: 'Roboto', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-family: 'Rubik Beastly', cursive;
  font-family: 'Rubik Bubbles', cursive;
  font-family: 'Rubik Burned', cursive;
  font-family: 'Rubik Glitch', cursive;
  font-family: 'Rubik Iso', cursive;
  font-family: 'Rubik Mono One', sans-serif;
  font-family: 'Rubik Puddles', cursive;
  font-family: 'Rubik Spray Paint', cursive;
  font-family: 'Rubik Vinyl', cursive;
  font-family: 'Space Mono', monospace;
  font-family: 'Titan One', cursive;
  font-family: 'UnifrakturMaguntia', cursive;

ADOBE
  font-family: "acumin-variable", sans-serif;
  font-family: "tephra", sans-serif;

PEROSNAL
  font-family: "adelphe";
*/

*{
  margin: 0;
  padding: 0;

  font-family: Arial, Helvetica, sans-serif;

  text-decoration: none;

  cursor: url('../public/cursor.png') 2 2, auto;

  scroll-behavior: smooth;

}
*::selection{
  color: black;
  text-shadow: 1px 1px 2px yellow, 0 0 5px yellow, 0 0 0.2em yellow;
}

.contributers a::selection, div::selection{
  color: black!important;
  text-shadow: 1px 1px 2px yellow, 0 0 5px yellow, 0 0 0.2em yellow!important;
}

.agenda a::selection, div::selection{
  color: black!important;
  text-shadow: 1px 1px 2px yellow, 0 0 5px yellow, 0 0 0.2em yellow!important;
}

a:visited, a:link{
  color: black;
}
a:hover{
  color: red!important;
}
strong{
  font-style: italic;
  font-family: var(--font-strong-family)!important;
}
blockquote{
  font-style: italic;
  font-family: var(--font-em-family);
  font-size: 1vw;
  /* line-height: 1!important; */
  margin-bottom: 2vh;

}
button{
  cursor: crosshair;
}


.outlet{
  position: absolute;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}
/* .outletScroll{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
} */
.outletInsitu{
  position: absolute;
  width: 99vw;
  height: 100vh;

}

.loading{
  margin-top: 45vh;
  text-align: center;

  font-size: 5vw;
  font-family: Arial, Helvetica, sans-serif;
}

.backgroundImage{
  width: 100%;
  height: auto;
  object-fit: cover;

  position: absolute;
}

.Comingsoonpage video{
  position: absolute;

  z-index: 1;

}

.comingSoonText{
  z-index: 2;
  position: absolute;

  width: 100%;
  color: white;
  margin-top: 40vh;
  text-align: center;

  font-size: 5vw;
  font-family: "EB Garamond";
  font-style: italic;

}

/*********************/
/*  Scrollbar page   */
/*********************/

*::-webkit-scrollbar {
  padding: 0;
  margin: 0;
  background-color: transparent;  
  border-radius: 10px;
  width: 10px;
  height: 10px;
}
  
*::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  background-color: transparent;
  border-radius: 10px;}

*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: white; 

} 

/*  contributers scrollbar   */

.contributer__wrapper::-webkit-scrollbar {
  padding: 0;
  margin: 0;
  background-color: transparent;  
  border-radius: 10px;
  width: 10px;
  height: 10px;
}
  
.contributer__wrapper::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  background-color: transparent;
  border-radius: 10px;}

  .contributer__wrapper::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent; 

} 

/*  contributers scrollbar   */

.agenda__container::-webkit-scrollbar {
  padding: 0;
  margin: 0;
  background-color: transparent!important;  
  border-radius: 10px;
  width: 10px;
  height: 10px;
}
  
.agenda__container::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  background-color: transparent!important;
  border-radius: 10px;}

.agenda__container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: transparent!important; 

} 

video::-internal-media-controls-overlay-cast-button {
  display: none;
}


@media only screen and (max-width: 450px) {
  /****************************
  *        displayNone        *
  ****************************/


  /****************************
  *          Changes          *
  ****************************/
  .Comingsoonpage video{  
    height: 100vh;
    width: auto;
  }
  
  .comingSoonText{  
    font-size: 10vw;
  }
  
}