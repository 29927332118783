@font-face {
    font-family: 'adelphe';
    src: url('adelphe-fructidorregular-webfont.woff2') format('woff2'),
         url('adelphe-fructidorregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'testue';
    src: url('testue-webfont.woff2') format('woff2'),
         url('testue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tribalium';
    src: url('tribaliumneue-regular-webfont.woff2') format('woff2'),
         url('tribaliumneue-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'vg5000';
    src: url('vg5000-regular_web-webfont.woff2') format('woff2'),
         url('vg5000-regular_web-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'wondertype';
    src: url('wondertype-regular-webfont.woff2') format('woff2'),
         url('wondertype-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: '0xa000';
    src: url('0xa000-dots-webfont.woff2') format('woff2'),
         url('0xa000-dots-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'arkes dense';
    src: url('ark-es-denseregular-webfont.woff2') format('woff2'),
         url('ark-es-denseregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'arkes solid';
    src: url('ark-es-solidlight-webfont.woff2') format('woff2'),
         url('ark-es-solidlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ft88g';
    src: url('ft88-gothique-webfont.woff2') format('woff2'),
         url('ft88-gothique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'heavy';
    src: url('heavy-webfont.woff2') format('woff2'),
         url('heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'messapia';
    src: url('messapia-regular-webfont.woff2') format('woff2'),
         url('messapia-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'punknova';
    src: url('punknova-regular-webfont.woff2') format('woff2'),
         url('punknova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}