.Nan{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;

    background-color: black;
}

/************************
*        VIDEO          *
*************************/
.Nan__backgroundVideo{
    width: 100%;
    height: auto;
    overflow: hidden;

    position: relative;
}
.Nan__backgroundVideo video{
    z-index: 1;

    margin-top: -5vw;
}
.Nan__backgroundVideo__wrapper{
    width: 100%;
    height: 20%;

    margin-top: 20vw;

    position: absolute;
    color: white;
    /* mix-blend-mode: difference; */
    z-index: 4;

    display: flex;
    justify-content: center;

}
.Nan__backgroundVideo__title{
    padding: 1vw;
}
.Nan__backgroundVideo__title h1{
    font-size: var(--font-a-size-h1);
    font-family: var(--font-a-family-h1);

}
.Nan__backgroundVideo__title h2{
    font-size: var(--font-a-size-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;

}


/************************
*       ARTICLE         *
*************************/
.Nan__container{
    position: relative;
    height: auto;
}
.Nan__container > *{
    color: white;

}

.Nan__container__title{
    text-align: center;
    padding: 10vh 0;
}

.Nan__container__title h1{
    color: white;
    font-size: var(--font-a-size-body-h1);
    font-family: var(--font-a-family-h1);

}
.Nan__container__title h2{
    margin-top:  2vh;
    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

.Nan__container__intro{
    padding: 0 25vw 0 25vw;
    /* margin-top: 1vw; */
    margin-bottom: 4vw;

}
.Nan__container__intro img{
    width: 100%;

}
.Nan p{
    font-size: 1vw;
    font-family: 'garamond';
    font-style: italic;
}
.Nan__intro__text{
    padding: 4.6vw 0 5vw 0!important;

    font-size: 2vw!important;
    font-family: 'garamond';
    font-style: italic;   
    text-align: center; 
}


.Nan__container__body{
    display: flex;
    height: fit-content;
}

.Nan__container__body > *{
    width: 50%;
}

.Nan__body__text{
    height: fit-content;
    padding: 2.5vw 10vw 7.5vw 10vw;
    /* background-color: red; */
}

.Nan__body__text div{
    margin-top:  3.2vw;
}

.Nan__container__body h2{
    margin-top: 3.4vw;
    margin-bottom: 1vw;

    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-body-h2);
    font-weight: 400;
}
.Nan__container__body div{
    margin-bottom: 1vw;

    font-size: 1.3vw;
    font-family: 'Garamond';
    font-weight: 400;
}

.Nan__body__image {
    height: auto;

    /* display: flex;
    justify-content: center; */


}
.Nan__body__image img{
    height: 35vw;

    padding-top: 4vw;
    padding-bottom: 7.5vw;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

}

.Nan__big__img{
    width: 100%;
}

.Nan__text__4{
    padding-top: 3.6vw;
}


.Nan__body__text__middle{
    width: 100%;
    padding: 2.5vw 30vw 2.5vw 30vw;
}

.Nan__download__button{
    padding: 1vw 2vw;
    border-radius: 20px;
    width: fit-content;
    background-color: white;
}
.Nan__download__button a{    
    color: black!important;
    font-family: 'EB Garamond', serif;
    font-style: italic;

}



/************************
*       SLIDER         *
*************************/
.Nan .swiper {
    width: 99vw;
    height: auto;
}
  
.Nan .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: black;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.Nan .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.NAN_article__flex{
 display: flex;
}

.NAN_article__flex img{
    width: 33vw;
    height: auto;
}

.Nan-slider6_1{
    width: 99vw;
    margin-top: -20vh;
}
.Nan-slider5_1{
    width: 80vw;
    margin-left: 10vw;
    /* margin-top: -20vh; */
}

.Nan-slider3_1{
    width: 27vw!important;
    height: auto;
    margin-left: 4vw;

}

@media only screen and (max-width: 450px) {



    /****************************
    *          Changes          *
    ****************************/
    .Nan__backgroundVideo video{
        margin-left: -70%;
        width: 250%;
    }

    .Nan__backgroundVideo__wrapper{
        margin-top: 50vw;
    }
    .Nan__backgroundVideo__title{
        padding: 1vw;
    }
    .Nan__backgroundVideo__title h1{
        font-size: 10vw;
    }
    .Nan__backgroundVideo__title h2{
        font-size: 5vw;    
    }
    
    /************************
    *       ARTICLE         *
    *************************/
    .Nan__container__title{
        text-align: center;
        padding: 5vh 0;
    }
    .Nan__container__title h1{
        font-size: 8vw;
    }
    .Nan__container__title h2{
        font-size: 4vw;
    }

    .Nan__container__intro{
        padding: 0 15vw 0 15vw;
        margin-bottom: 4vw;
    }
    .Nan p{
        font-size: 2.5vw;
    }
    .Nan__intro__text{

        font-size: 3vw!important;
    }

    .Nan__container__body > *{
        width: 50%;
    }

    .Nan__body__text{
        height: fit-content;
        padding: 2.5vw 10vw 7.5vw 10vw;
        /* background-color: red; */
    }

    .Nan__body__text div{
        margin-top:  3.2vw;
    }

    .Nan__container__body h2{
        margin-top: 3.4vw;
        margin-bottom: 1vw;

        font-size: 5vw;
    }
    .Nan__container__body div{

        font-size: 3vw;
    }

    .Nan__body__image {
        height: auto;

        /* display: flex;
        justify-content: center; */


    }

    .Nan__body__text__middle{
        width: 100%;
        padding: 2.5vw 15vw 7.5vw 15vw;
    }


    .Nan-slider6_1{
        width: 99vw;
        margin-top: -10vh;
    }
}
