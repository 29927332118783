.agenda{
    width: 100%;
    height: 100%;

    overflow-y: hidden;
}


.agenda__backgroundVideo{
    width: 100%;
    height: 100vh;
    overflow: hidden;

    position: fixed;
    z-index: -1;
}
.agenda__backgroundVideo video{
    width: 100%;
    height: auto;
}

.agenda__container{
    position: absolute;
    width: 100%;
    height: 100vh;


    /* margin-left: 7vw; */
}

.agenda__container__content{
    width: 100%;
    height: 100vh;

    /* background-color: green; */

}
.agenda .swiper{
    width: 100%;
    height: 100%;
    
    background-color: transparent!important;
    opacity: 1!important;

}

.agenda .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}


.agenda .swiper-slide img{
    width: 28vw;
}

.agenda .swiper-button-next::after{
    color: white;
    font-size: 1.5vw!important;
    transform: rotate(90deg);
    margin-top: 0.3vw;
}
.agenda .swiper-button-next{
    position: fixed;
    margin-top: 5vh;
    right: 2vw;

    padding: 0.3vw;
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 50px;

    cursor: crosshair;

    display: flex;
    justify-content: center;
    align-items: center;

}

.agenda .swiper-button-prev::after{
    color: white;
    font-size: 1.5vw!important;
    transform: rotate(90deg);
    margin-top: -0.1vw;

}

.agenda .swiper-button-prev{
    position: fixed;

    left: 95.2vw;


    padding: 0.3vw;
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 50px;

    cursor: crosshair;

    display: flex;
    justify-content: center;
    align-items: center;

}








@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/


    /****************************
    *          Changes          *
    ****************************/
    .agenda__backgroundVideo{
        width: auto;
        height: 100vh;
        overflow: hidden;
    
        position: fixed;
        z-index: -1;
    }
    .agenda__backgroundVideo video{
        width: auto;
        height: 100%;
    }

    .agenda .swiper-slide img{
        width: 50vw;
    }
    
    .agenda .swiper-button-next::after{
        color: white;
        font-size: 2.5vw!important;
        transform: rotate(0deg);
        margin-right: -0.5vw;

        display: none;
    }
    .agenda .swiper-button-next{
        position: fixed;
        margin-top: 40vh;
        right: 40vw;
    
        padding: 0.3vw;
        width: 5vw;
        height: 5vw;
        border-style: solid;
        border-color: white;
        border-width: 2px;
        border-radius: 50px;
    
        cursor: crosshair;
    
        display: flex;
        justify-content: center;
        align-items: center;

        display: none;
    }
    
    .agenda .swiper-button-prev::after{
        color: white;
        font-size: 2.5vw!important;
        transform: rotate(0deg);
        margin-right: 0.5vw;
        
        display: none;
    }
    
    .agenda .swiper-button-prev{
        left: 40vw;
        margin-top: 40vh;
    
    
        padding: 0.3vw;
        width: 5vw;
        height: 5vw;
        border-style: solid;
        border-color: white;
        border-width: 2px;
        border-radius: 50px;
        
        display: none;

    }
}