:root{
    /********************
    *       menu        *
    *********************/
    --font-menu-size-h1: 1.5vw;
    --font-menu-size-li: 1vw;

    --red: 0;
    --green: 0;
    --blue: 0;
    --threshold: 0.5;
    --border-threshold: 0.8;

    --font-strong-family: 'Fredoka';
    --font-em-family: 'EB Garamond';

    /**********************
    *       header        *
    ***********************/
    --font-h-size-h1: 4vw;
    --font-h-size-h2: 1.2vw;
    --font-h-size-h3: 0.9vw;
    --font-h-size-button: 0.8vw;

    --font-h-family-h1: 'EB Garamond';
    --font-h-family-h2: 'Space Mono';
    --font-h-family-h3: 'Space Mono';
    --font-h-family-button: 'Space Mono';

    /**********************
    *       Home        *
    ***********************/
    --font-home-size-h1: 4vw;
    --font-home-size-h2: 1.1vw;
    --font-home-size-p: 1vw;

    --font-home-family-h1: 'acumin-variable';
    --font-home-family-h2: 'acumin-variable';
    --font-home-family-p: 'EB Garamond';



    /************************
    *       INSITUS         *
    *************************/
    --font-i-size-h1: 2vw;
    --font-i-size-h2: 1.5vw;

    /************************
    *       Article         *
    *************************/
    --font-a-size-h1: 4vw;
    --font-a-size-h2: 2vw;

    --font-a-size-body-h1: 5vw;
    --font-a-size-intro: 1.5vw;
    --font-a-size-body-h2: 1.6vw;
    --font-a-size-body-h3: 1.3vw;
    --font-a-size-p: 1.3vw;
    --font-a-size-strong: 1vw;
    --font-a-size-em: 1.1vw;
    --font-a-size-quote: 5.5vw;
    --font-a-size-pdf: 1.3vw;


    --font-a-family-h1: 'acumin-variable';
    --font-a-family-h2: 'EB Garamond';
    --font-a-family-intro: 'EB Garamond';
    --font-a-family-body-h2: 'acumin-variable';
    --font-a-family-body-h3: 'acumin-variable';
    --font-a-family-p: 'EB Garamond';
    --font-a-family-quote: 'EB Garamond';
    --font-a-family-pdf: 'EB Garamond';

    /************************
    *     Contributer       *
    *************************/
    --font-c-family-link: 'adelphe';

    /************************
    *        about          *
    *************************/
    --font-about-size-p: 1.3vw;
    --font-about-size-bullet: 3vw;
    --font-about-size-coords: 2vw;


    --font-about-family-p: 'adelphe';
    --font-about-family-bullet: 'EB Garamond';
    --font-about-family-coords: 'adelphe';

    /************************
    *       Archive         *
    *************************/
    --font-ar-size-h1: 1.5vw;
    --font-ar-size-h2: 1vw;
    --font-ar-size-h4: 10vw;

    --font-ar-family-h1: 'acumin-variable';
    --font-ar-family-h2: 'EB Garamond';
    --font-ar-family-h4: 'EB Garamond';

    /************************
    *       Agenda         *
    *************************/
    --font-ag-size-h1: 4vw;

    --font-ag-family-h1: 'acumin-variable';


}