.home{
    width: 100%;
    height: 100%;

    /* overflow-y: scroll; */
    overflow: hidden;

    position: relative;
}

.home .home__container{
    margin-top: 45vh;
    text-align: center;

    font-size: 5vw;
    font-family: Arial, Helvetica, sans-serif;
    
}
.home__wrapper__video{
    width: 100vw;
    height: 100vh;

    overflow: hidden;
    background-color: white;
    position: absolute;

    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.home__website__info{
    position: absolute;
    width: 70vw;
    height: 49vh;

    bottom: 1vh;
    right: 0;

    color: white;
    
}
.home__website__info .website__info{
    position: absolute;
    padding: 1.5vw;
    width: 62vw;

    /* background-color: white; */
    backdrop-filter: blur(10px) saturate(70%);

    border-color: white;
    border-style: solid;
    border-width: 2px;

    display: none;

}

.website__info div{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1vh;

}
.website__info h1{
    color: red;
    width: 100%;
    margin-bottom: 1vh;
    font-family: var(--font-home-family-h1);
}
.website__info h2{
    color: red;
    font-size: var(--font-home-size-h2);
    margin-right: 0.4vw;
    font-family: var(--font-home-family-h2);


}
.website__info p{
    font-size: var(--font-home-size-p);
    font-family: var(--font-home-family-p);
    font-style: italic;

    align-self: flex-end;
    margin-right: 0.3vw;
}

.website__info p strong{
    font-size: 0.9vw!important;
}
.firstP{
    margin-bottom: 2vh;
}
.home__website__info .infoButton{
    position: absolute;
    width: 1.7vw;
    padding: 0.5vw;
    bottom: 1vh;
    right: 2vw;

    color: white;
    text-align: center;

    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 50px;

    font-size: 1.4vw;
    font-family: var(--font-home-family-p);
    font-style: italic;

}

.mobile{
    display: none;
}
.home__mobile{
    display: none;
}

@media only screen and (max-width: 450px) {
    .mobile{
        display: block;
    }


    /***************************
    *       displayNone        *
    ****************************/
    .home__container, .home__website__info, .home__desktop{
        display: none;
    }

    /***************************
    *       homepage        *
    ****************************/
    .home__mobile{
        height: 100vh;
    
        overflow: hidden;
        background-color: white;
        position: absolute;

        display: flex;
        justify-content: center;
    }
    .home__mobile video{
        height: 100%;
        width: auto;
    }
    
}

