.toolkitweek{
    width: 90vw;
}

.toolkitweek__scroll{
    background-color: black;
    width: 100vw;
    height: 100vh;

    overflow-y: scroll;
}

.toolkitweek img{
    width: 100%;
}