.about{
    width: 100%;
    height: 100%;

    overflow: scroll;
}

.smooth{
    scroll-behavior: smooth!important;
}

.about__container{
    position: relative;
    width: 450vw;
}

.about__container__map {
    width: 100%;
    z-index: -1;

    position: absolute;
}

.about__container__map  .about__svg__map{
    margin: 40vw;
    margin-bottom: 60vw;
    width: 350vw;
}

.about__svg__map .cls-1 {
    fill: #fff;
}

.about__svg__map .cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.about__svg__map .cls-3 {
    fill: red;
}
.about__svg__principles {
    position: absolute;
    width: 90vw;

    z-index: -1;

    left: 310vw;
    top: 193vw;
}

.about__svg__principles .cls-1 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
}

.about__container__text{
    color: black;
}

.about__body > p{
    font-size: var(--font-about-size-p);
    font-family: var(--font-about-family-p);
    margin-bottom: 2vh;
    line-height: 1.1;
}

.about__container__titleCD{
    /* background-color: blue; */
    width: 1vw;
    height: 1vh;
    left: 223vw;
    top: 151vw;
}

.about__body{
    position: absolute;
}

.about__container__shortly-explained{
    width: 35vw;
    left: 197vw;
    top: 176vw;

    padding: 10vw;
}

.about__container__vision{
    width: 40vw;
    left: 273vw;
    top: 131.5vw;

    /* background-color: blue; */
    padding: 10vw;
}

.about__container__character-nature{
    width: 40vw;
    left: 258vw;
    top: 52vw;

    padding: 10vw 20vw;

}

.about__container__collaboration{
    width: 30vw;

    left: 310vw;
    top: 80vw;

    display: none;
}
.about__container__composition{
    width: 30vw;

    left: 310vw;
    top: 85vw;

    display: none;
}

.about__container__1{
    width: 40vw;
    left: 363vw;
    top: 30.5vw;

    padding: 10vw 20vw;


}
.about__container__1 ul{
    margin-left: 2vw;
    margin-bottom: 2vh;
}
.about__container__1 li{
    font-size: var(--font-about-size-p);
    font-family: var(--font-about-family-p);
    margin-bottom: 0.2vw;
    line-height: 1.1;
}

.about__container__top{
    width: 40vw;
    left: 364vw;
    top: 75vw;

    padding: 10vw 20vw;

}
.about__container__up{
    width: 40vw;
    left: 350.7vw;
    top: 110vw;

    padding: 10vw 20vw;
}
.about__container__psychogeography{
    width: 50vw;
    left: 336vw;
    top: 155vw;

    padding: 10vw 20vw;

}
.about__container__dynamics{
    left: 347vw;
    top: 200vw;

    display: none;
}

.about__container__flexibility{
    left: 335vw;
    top: 219vw;

    display: none;
}
.about__container__variety{
    left: 390vw;
    top: 211vw;

    display: none;
}
.about__container__complexity{
    left: 362vw;
    top: 209vw;

    display: none;
}
.about__container__subjective-expression{
    left: 397vw;
    top: 216vw;

    display: none;
}
.about__container__creativity{
    left: 373vw;
    top: 197vw;

    display: none;
}
.about__container__surprise{
    left: 400vw;
    top: 234vw;

    display: none;
}
.about__container__nudity{
    left: 368vw;
    top: 228vw;

    display: none;
}
.about__container__playfulness{
    left: 360vw;
    top: 239vw;

    display: none;
}
.about__container__insitu-ateliers{
    width: 40vw;
    left: 16vw;
    top: 50.5vw;

    padding: 10vw 20vw;

}
.about__container__workshop{
    width: 40vw;
    left: 90vw;
    top: 150vw;
}
.about__container__event{
    width: 40vw;
    left: 90vw;
    top: 175vw;
}
.about__container__analysis{
    width: 40vw;
    left: 90vw;
    top: 190vw;
}
.about__container__analysis ul{
    margin-left: 2vw;
    margin-bottom: 1vw;
}
.about__container__analysis li{
    font-size: var(--font-about-size-p);
    font-family: var(--font-about-family-p);
    margin-bottom: 0.2vw;
    line-height: 1.1;
}
.about__container__outcome{
    width: 40vw;
    left: 90vw;
    top: 219vw;
}
.about__container__the-end{
    width: 40vw;
    left: 140vw;
    top: 220vw;

    color: red;

    /* background-color: blue; */
    padding: 10vw 20vw;

}
.about__container__title{
    width: 40vw;
    left: 159vw;
    top: 225vw;
}

.about__container__title h1{
    color: red;

    font-style: italic;
    font-size: var(--font-about-size-bullet);
    font-family: var(--font-about-family-bullet); 
    font-weight: 400;
}


.bullet_1{
    top: 145vw;
}
.bullet_2{
    top: 170.5vw;
}
.bullet_3{
    top: 185.3vw;
}
.bullet_4{
    top: 214vw;
}
.about__container__bullet{
    width: 3vw;
    height: 3vw;

    left: 85vw;

    text-align: center;


    position: absolute;
    color: white;
    background-color: red;
    border-radius: 50px;
    padding: 1vw;
}
.about__container__bullet > *{
    font-style: italic;
    font-size: var(--font-about-size-bullet);
    font-family: var(--font-about-family-bullet); 
    font-weight: 400;
    margin-top: -0.6vw;
}


#collaboration, #composition, #dynamics, #creativity, #complexity, #flexibility, #variety, #subjective-expression, #nudity, #surprise, #playfulness, #workshop, #event, #analysis, #outcome{
    width: 40vw;

    position: absolute;
    color: white;
    background-color: red;
    border-radius: 15px;
    padding: 2vw;
}
#collaboration, #composition, #dynamics, #creativity, #complexity, #flexibility, #variety, #subjective-expression, #nudity, #surprise, #playfulness p{
    font-size: var(--font-about-size-p);
    font-family: var(--font-about-family-p);
    margin-bottom: 2vh;
    line-height: 1.1;
}


.container__hover{
    width: 24vw;
    height: 5vw;

    background-color: transparent!important;
}
.about__container__hover1{
    left: 285vw;
    top: 75vw;
}
.about__container__hover2{
    left: 285vw;
    top: 80.5vw;
}
.about__container__hover3{
    width: 32vw;
    height: 8vw;

    left: 314vw;
    top: 195vw;
    transform: rotate(-8deg);
}
.about__container__hover4{
    width: 28vw;
    height: 7.5vw;

    left: 308vw;
    top: 210vw;
    transform: rotate(8deg);
}
.about__container__hover5{
    width: 26vw;
    height: 8vw;

    left: 366vw;
    top: 204vw;
    transform: rotate(4deg);
}
.about__container__hover6{
    width: 25vw;
    height: 7.5vw;

    left: 336vw;
    top: 204vw;
    transform: rotate(-8deg);
}
.about__container__hover7{
    width: 75vw;
    height: 7vw;

    left: 323vw;
    top: 217vw;
    transform: rotate(-8deg);
}
.about__container__hover8{
    width: 18vw;
    height: 7vw;

    left: 356vw;
    top: 192vw;
    transform: rotate(3deg);
}
.about__container__hover9{
    width: 24vw;
    height: 6vw;

    left: 376vw;
    top: 226vw;
    transform: rotate(16deg);
}
.about__container__hover10{
    width: 16vw;
    height: 6vw;

    left: 352vw;
    top: 225vw;
    transform: rotate(-12deg);
}
.about__container__hover11{
    width: 43vw;
    height: 7vw;

    left: 318vw;
    top: 236vw;
    transform: rotate(-10deg);
}




.about__container__coordinants{
    position: fixed;
    width: 20vw;
    height: 5vh;
    /* background-color: blue; */
    bottom: 0;
}

.about__container__coordinants h1{
    margin-left: 1vw;

    font-size: var(--font-about-size-coords);
    font-family: var(--font-about-family-coords); 
    font-weight: 400;
}

.about__container__Buttons{
    position: fixed;
    width: 5vw;
    height: 5vh;
    /* background-color: blue; */
    bottom: 0.7vw;
    margin-left: 47%;
    display: flex;
    justify-content: space-between;
}

.about__container__Buttons button{
    height: fit-content;
    padding: 0.5vw;

    font-size: 1vw;

    background-color: transparent;
    
    border-style: solid;
    border-radius: 50px;
    border-color: black;
    border-width: 2px;
}
.about__container__Buttons button:hover{
    height: fit-content;
    padding: 0.5vw;

    font-size: 1vw;
    color: white;

    background-color: red;

    border-style: solid;
    border-radius: 50px;
    border-color: red;
    border-width: 2px;
}


.about__container__anchors{
    z-index: -1;
    position: absolute;
    width: 50vw;
    height: 30vh;

}

.anchorShort{
    left: 190vw;
    top: 150vw;
}
.anchorClubRe{
    width: 70vw;

    left: 220vw;
    top: 105vw;
}
.anchorVision{
    width: 70vw;

    left: 270vw;
    top: 105vw;
}
.anchorCharacter{
    left: 270vw;
    top: 85vw;
}
.anchorDev{
    width: 70vw;
    left: 290vw;
    top: 105vw;
}
.anchor1{
    width: 70vw;
    left:330vw;
    top: 75vw;
}
.anchorTopUp{
    left:330vw;
    top: 80vw;
}
.anchorSituationists{
    left:210vw;
    top: 145vw;
}
.anchorPsycho{
    width: 70vw;
    left: 310vw;
    top: 155vw;
}
.anchorDesign{
    left:270vw;
    top: 170vw;

}
.anchorDynamics{
    width: 70vw;
    left: 333vw;
    top: 190vw;
}
.anchorPartici{
    left: 160vw;
    top: 125vw;
}
.anchorInsitu{
    left: 95vw;
    top: 100vw;
}
.anchorResearch{
    left: 110vw;
    top: 115vw;
}
.anchorWorkshop{
    left: 20vw;
    top: 130.5vw;
}


.hoverLogo{
    position: absolute;
    width: 3vw;

    left: 307.5vw;
    top: 74vw;
}
.hoverLogo .cls-1 {
    fill: red;
}





/* MINIMAP */
.minimap__container{
    position: fixed;
    bottom: 5vh;
    left: 1vw;

    width: 20vw;
    z-index: 5;
}

.minimap__size{
   position: relative;
   z-index: 4;
   
   border: 2px solid white;

}

.minimap__viewer{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    z-index: 5;
    border: 1px solid red;
    border-radius: 5px;
}
.minimap__content{
    position: absolute;
    top: O;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    transform-origin: 0 0;
    border: 1px solid white;
}


.anchorLookAround{
    margin-top: 40vh;
    margin-left: 35vw;

    color: red;
    font-size: 6vw;
    font-family: 'EB Garamond';
    font-style: italic;
}


@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/
    .anchorLookAround, .about__container__coordinants{
        display: none;
    }

    /****************************
    *          Changes          *
    ****************************/
   
    .about__container__map  .about__svg__map{
        margin: 40vw;
        margin-bottom: 60vw;
        width: 350vw;
    }
    .about__container__Buttons{
        display: none;
    }
    .about__container__anchors{
        display: none;
    }


    .about__container__character-nature{
        top: 48vw;
        
    }

    .about__container__event{
        top: 180vw;
    }
    .about__container__analysis{
        top: 194.5vw;
    }
    .about__container__outcome{
        top: 228.4vw;
    }
    .bullet_2{
        top: 175.5vw;
    }
    .bullet_3{
        top: 190.3vw;
    }
    .bullet_4{
        top: 225vw;
    }
    
}