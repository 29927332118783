.twoTowers{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;
}

/************************
*        VIDEO          *
*************************/
.twoTowers__backgroundVideo{
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: relative;
}
.twoTowers__backgroundVideo video{
    z-index: 1;
}
.twoTowers__backgroundVideo__wrapper{
    width: 100%;
    height: 20%;

    margin-top: 20vw;

    position: absolute;
    color: white;
    /* mix-blend-mode: difference; */
    z-index: 4;

    display: flex;
    justify-content: center;
}
.twoTowers__backgroundVideo__title{
    padding: 1vw;
}
.twoTowers__backgroundVideo__title h1{
    font-size: var(--font-a-size-h1);
    font-family: var(--font-a-family-h1);
}
.twoTowers__backgroundVideo__title h2{
    font-size: var(--font-a-size-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

/************************
*       ARTICLE         *
*************************/

.twoTowers__container{
    position: relative;
    height: auto;
}
.twoTowers__container__title{
    text-align: center;
    padding: 10vh 0;
}
.twoTowers__container__title h1{
    color: black;
    font-size: var(--font-a-size-body-h1);
    font-family: var(--font-a-family-h1);
}
.twoTowers__container__title h2{
    margin-top:  2vh;
    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

.twoTowers__container__images{
    margin: 10vw 0;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}
.twoTowers__container__images img{
    height: 100%;
}

.twoTowers__img1, .twoTowers__img4, .twoTowers__img5, .twoTowers__img9, .twoTowers__img18, .twoTowers__img24, .twoTowers__img30, .twoTowers__img31{
    width: 45vw;
}
.twoTowers__img2, .twoTowers__img10, .twoTowers__img25{
    width: 30vw;
}
.twoTowers__img3, .twoTowers__img14, .twoTowers__img15{
    width: 35vw;
}
.twoTowers__img6, .twoTowers__img7, .twoTowers__img8, .twoTowers__img11, .twoTowers__img12, .twoTowers__img13, .twoTowers__img16, .twoTowers__img19, .twoTowers__img20, .twoTowers__img26, .twoTowers__img32{
    width: 65vw;
}
.twoTowers__container__images7, .twoTowers__container__images12, .twoTowers__container__images16, .twoTowers__container__image19, .twoTowers__container__images26{
    justify-content: end;
}
.twoTowers__img17, .twoTowers__img21, .twoTowers__img22, .twoTowers__img29{
    width: 100%;
}
.twoTowers__video3, .twoTowers__video4{
    width: 35vw;
}
.twoTowers__img23{
    width: 41.5vw;
}

@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/
    .twoTowers__backgroundVideo{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    
        position: relative;
        display: flex;
        justify-content: center;
    }
    .twoTowers__backgroundVideo video{
        width: auto;
        height: 100vh;
    }
    .twoTowers__backgroundVideo__wrapper{   
        margin-top: 45vh;
    }

    .twoTowers__backgroundVideo__title h1{
        font-size: 5vw;
    }
    .twoTowers__backgroundVideo__title h2{
        font-size: 4vw;
    }


    /****************************
    *          Changes          *
    ****************************/
    .twoTowers{
        width: 103%;
        overflow-x: hidden;
    }
    /* .twoTowers__container__title{
        margin-top: 7vh;
        width: 100%;
        margin-left: 6vw;
        padding: 0;
        margin-bottom: 5vw;
        text-align: center;
        width: fit-content;
    } */
    .twoTowers__container__title h1{
        font-size: 8vw;
        margin-bottom: 3vw;
    }
    .twoTowers__container__title h2{
        font-size: 4vw!important;
        font-family: "EB Garamond"!important;
        margin-top: 0!important;
    }

}

