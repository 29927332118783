.woodblocks{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;
}

/************************
*        VIDEO          *
*************************/
.woodblocks__backgroundVideo{
    width: 100%;
    height: 100%;
    overflow: hidden;

    position: relative;
}
.woodblocks__backgroundVideo video{
    z-index: 1;
}
.woodblocks__backgroundVideo__wrapper{
    width: 100%;
    height: 20%;

    margin-top: 20vw;

    position: absolute;
    color: white;
    /* mix-blend-mode: difference; */
    z-index: 4;

    display: flex;
    justify-content: center;
}
.woodblocks__backgroundVideo__title{
    padding: 1vw;
}
.woodblocks__backgroundVideo__title h1{
    font-size: var(--font-a-size-h1);
    font-family: var(--font-a-family-h1);
}
.woodblocks__backgroundVideo__title h2{
    font-size: var(--font-a-size-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

/************************
*       ARTICLE         *
*************************/

.woodblocks__container{
    position: relative;
    height: auto;
}
.woodblocks__container__title{
    text-align: center;
    padding: 10vw 0 4vw 0;
}
.woodblocks__container__title h1{
    color: black;
    font-size: var(--font-a-size-body-h1);
    font-family: var(--font-a-family-h1);
}
.woodblocks__container__title h2{
    margin-top:  2vh;
    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

.woodblocks__container__images{
    margin: 10vw 0;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
}

.woodblocks__container__images img{
    height: 100%;
}

.woodblocks__img1, .woodblocks__img4, .woodblocks__img11, .woodblocks__img27, .woodblocks__img32{
    width: 100%;
}

.woodblocks__img2, .woodblocks__img6, .woodblocks__img9, .woodblocks__img10, .woodblocks__img12, .woodblocks__img15, .woodblocks__img18, .woodblocks__img20, .woodblocks__img21, .woodblocks__img23, .woodblocks__img25, .woodblocks__img26, .woodblocks__img28, .woodblocks__img31{
    width: 45vw;
}

.woodblocks__img3, .woodblocks__img5, .woodblocks__img13, .woodblocks__img14, .woodblocks__img24, .woodblocks__img29, .woodblocks__img30{
    width: 35vw;
}

.woodblocks__img7, .woodblocks__img8, .woodblocks__img19, .woodblocks__img22{
    width: 65vw;
}

.woodblocks__img16, .woodblocks__img33, .woodblocks__img34, .woodblocks__img35{
    width: 60vw;
}

.woodblocks__img17{
    width: 26.7vw;
}

.woodblocks__container__images7, .woodblocks__container__images19, .woodblocks__container__images23, .woodblocks__container__images34{
    justify-content: end;
}







@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/

    .woodblocks__backgroundVideo{
        width: 100%;
        height: fit-content;
        overflow: hidden;
    
        position: relative;
        display: flex;
        justify-content: center;
    }
    .woodblocks__backgroundVideo video{
        width: auto;
        height: 100vh;
    }
    .woodblocks__backgroundVideo__wrapper{   
        margin-top: 45vh;
    }

    .woodblocks__backgroundVideo__title h1{
        font-size: 5vw;
    }
    .woodblocks__backgroundVideo__title h2{
        font-size: 4vw;
    }
    

    /****************************
    *          Changes          *
    ****************************/
    .woodblocks{
        width: 103%;
        overflow-x: hidden;
    }
    /* .woodblocks__container__title{
        margin-top: 7vh;
        width: 100%;
        margin-left: 6vw;
        padding: 0;
        margin-bottom: 5vw;
        text-align: center;
        width: fit-content;
    } */
    .woodblocks__container__title h1{
        font-size: 8vw;
        margin-bottom: 3vw;
    }
    .woodblocks__container__title h2{
        font-size: 4vw!important;
        font-family: "EB Garamond"!important;
        margin-top: 0!important;
    }

}

