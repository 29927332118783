.header{
    width: 98%;
    position: fixed;
    list-style: none;

    margin: 1vw;
    z-index: 5!important;
}

/************
*   LOGO    *
*************/
.header .logo{
    height: 5vw;
    width: 7.5vw;
    z-index: 4;

}
.header .logo svg{
    height: 100%;
}

/************
*   Menu    *
*************/
.header .menu{
    position: absolute;
    width: 20vw;
}
.header .menuContent{
    width: 15.5vw;
    margin-left: 0.3vw;
    margin-top: 1vh;

    padding: 1vw;
    
    /* background-color: white; */
    backdrop-filter: blur(10px) saturate(70%);

    border-color: black;
    border-style: solid;
    border-width: 2px;


    /* box-shadow: 0 0 15px 6px yellow; */

    display: none;
}


.header .menuContent h1{
    color: black!important;
    font-size: var(--font-menu-size-h1)!important;
}
.header .menuContent h1:hover{
    color: red!important;
    font-size: var(--font-menu-size-h1)!important;
}
.header .menuContent h2{
    color: black!important;

    font-size: var(--font-menu-size-h1)!important;
    /* font-family: var(--font-h-family-h1); */
    font-weight: 400;
}
.header .menuContent li{
    color: black!important;
    font-size: var(--font-menu-size-li)!important;
}
.header .menuContent li:hover{
    /* color: red!important; */
    font-size: var(--font-menu-size-li)!important;
}

.header .menu:hover .menuContent {display: block;}

.header .menu__active{
    color: white!important;
    border-color: white!important;
}

.header .menu__active h1{
    color: white!important;
}

.header .menu__active h2{
    color: white!important;
}

.header .menu__active li{
    color: white!important;
}

.header .menu__active  a{
    color: white!important;
}


/***************
*   Buttons    *
****************/
.header .menuButtons{
    width: 3vw;

    position: absolute;
    right: 1vw;
}

.header .marker{
    margin-top: 8vh;
}
.header .menuButtons svg .cls-1 {
    fill: #000000;
}

.header .menuButtons svg .cls-2 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}
.header .active .cls-1 {
    fill: white!important;
}

.header .active .cls-2 {
    fill: none;
    stroke: white!important;
}

.persoButton{
    z-index: 1;
}

.markerButton{
    z-index: 1;
}


/*************
*   popup    *
**************/
.menuButtons__popup{
    z-index: 2!important;
    width: 20vw;
    height: 89vh;
    position: absolute;

    margin-left: -17vw;

    background-color: white;
    box-shadow: 0 0 5px gray;
    /* border-color: gray; */
    /* border-style: solid; */
    border-radius: 20px;

    display: none;
}

.menuButtons__popup__personalize h1{
    width: 100%;
    margin-bottom: 0.5vh;

    text-align: center;
    color: lightgray;
    font-style: italic;
    font-family: var(--font-h-family-h1)!important;
    font-size: var(--font-h-size-h1)!important;
    font-weight: 400!important;

}

.menuButtons__popup__personalize h2{
    width: 100%;

    text-align: center;
    color: lightgray!important;
    font-family: var(--font-h-family-h2)!important;
    font-size: var(--font-h-size-h2)!important;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 2vh;
    margin-top: 4vh;
}
.menuButtons__popup__personalize h4{
    /* width: 100%; */

    text-align: center;
    color: lightgray;
    font-family: var(--font-h-family-h3)!important;
    font-size: var(--font-h-size-h3)!important;
    font-weight: 400;
    /* margin-top: 2vh; */

}
.menuButtons__popup__personalize #formBackground{
    display: flex;
    justify-content: center;
}

/* BACKGROUND */
.popup__personalize__background button{
    width: 1.3vw;
    height: 1.3vw;

    margin-right: 0.5vw;

    background-color: white;
    border: none;
    border-radius: 20px;
}



.popup__personalize__background form button:nth-child(1){
    background-color: white!important;
    border: solid;
    border-width: 2px;
    border-color: lightgray!important;
}.popup__personalize__background form button:nth-child(2){
    background-color: black!important;
}.popup__personalize__background form button:nth-child(3){
    background-color: red!important;
}.popup__personalize__background form button:nth-child(4){
    background-color: yellow!important;
}.popup__personalize__background form button:nth-child(5){
    background-color: lawngreen!important;
}.popup__personalize__background form button:nth-child(6){
    background-color: aqua!important;
}.popup__personalize__background form button:nth-child(7){
    background-color: blue!important;
}.popup__personalize__background form button:nth-child(8){
    background-color: fuchsia!important;
}.popup__personalize__background form button:nth-child(9){
    background-color: orange!important;
    margin-right: 0px;
}

.form__colorPickerBack{
    padding: 0 1vw;
    width: 15vw;
    margin-left: 1.5vw;
}

.inputHidden{
    display: none;
}
.personalize__background__image{
    display: flex;
    justify-content: center ;
    width: 100%;
}
.personalize__background__image h4{
    margin-right: 1vw;
    margin-top: 2vh;
}


.personalize__background__image label{
    width: 1vw;

    /* margin-bottom: 2vh; */
    margin-top: 1.6vh;
    padding: 0.1vh 0.3vw;

    color: lightgray;
    background: white;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}
.personalize__background__image input::-webkit-file-upload-button {
    visibility: hidden;
}
.personalize__background__image input::before {
    content: 'Select file';
    display: inline-block;
    background: white;
    border: 2px solid lightgray;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    /* -webkit-user-select: none; */
    cursor: pointer;
    color: lightgray;
    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;
    margin-bottom: 2vh;
    margin-top: 4vh;

}


/* TEXT */
.form__text__textFormat{
    display: flex;
    justify-content: center;
}
.form__textFormat__select{
    width: 5vw;

    padding: 0.2vw;
    width: 15vw;

    color: lightgray;

    border-radius: 7px;
    border-style: solid;
    border-color: lightgray;
    border-width: 2px;

    font-family: var(--font-h-family-button);
    font-size: var(--font-h-size-button);

}
.form__colorPicker{
    padding: 1vw;
    width: 15vw;
    margin-left: 1.5vw;
}
.hue-picker{
    width: 100%!important;
}

.popup__personalize__text button{
    margin-bottom: 1vh;
    margin-right: 1vw;
    padding: 0.3vw;

    color: lightgray;
    font-family: var(--font-h-family-button);
    font-size: var(--font-h-size-button);

    background-color: blue;
    border: none;
    border-radius: 5px;
}
.popup__personalize__text .form__text__textFormat button:focus{
    margin-bottom: 1vh;
    margin-right: 1vw;
    padding: 0.3vw;

    color: blue;
    font-family: var(--font-h-family-button);
    font-size: var(--font-h-size-button);

    background-color: white;
    border: none;
    border-radius: 5px;
}


.form__text__color{
    display: flex;
    justify-content: center;
}
.form__text__color button{
    width: 1.5vw;
    height: 1.5vw;

    margin-right: 0.5vw;

    background-color: white;
    border: none;
    border-radius: 20px;
}
.form__text__color button:nth-child(1){
    background-color: white!important;
    border: solid;
    border-width: 2px;
    border-color: lightgray!important;
}.form__text__color button:nth-child(2){
    background-color: black!important;
}.form__text__color button:nth-child(3){
    background-color: red!important;
}.form__text__color button:nth-child(4){
    background-color: yellow!important;
}.form__text__color button:nth-child(5){
    background-color: lawngreen!important;
}.form__text__color button:nth-child(6){
    background-color: aqua!important;
}.form__text__color button:nth-child(7){
    background-color: blue!important;
}.form__text__color button:nth-child(8){
    background-color: fuchsia!important;
}.form__text__color button:nth-child(9){
    background-color: orange!important;
    margin-right: 0px;
}


.form__text__size{
    padding: 1vh 1vw;
    width: 15vw;
    margin-left: 1.5vw;
}

.MuiSlider-root{
    color: lightgray!important;
}

.form__text__font{
    padding: 1vh 1vw;
    width: 15vw;
    margin-left: 1.5vw;
}

.personalize__background__font{
    padding: 1vh 1vw;
    width: 15vw;
    margin-left: 1.5vw;

    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    /* width: 100%; */
    /* background-color: red; */

}
.inputFont{

    margin-top: 1.6vh;
    padding: 0.1vh 0.3vw;

    color: lightgray;
    background: white;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;

}
.nameCheck{
    color: red;
}
.font-wrapper{
    display: flex;

}
.labelFont{
    display: block;
    width: 65%;
    height: 3.1vh;
    padding: 0.1vh 0.3vw;

    color: lightgray;
    background: white;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}

.submitButton{
    width: 35%;
    padding: 0.1vh 0.3vw;

    color: lightgray;
    background: white;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}

.saveButton{
    margin-top: 1vh;
    margin-left: 8.2vw;
    padding: 0 0.5vw;

    color: lightgray;
    background: white;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}










@media only screen and (max-width: 450px) {

    /************
    *   LOGO    *
    *************/
    .header .logo{
        height: 10vw;
        width: 7.5vw;
        z-index: 4;

    }
    .header .logo svg{
        height: 100%;
    }

    /************
    *   Menu    *
    *************/
    .menu{
        position: absolute;

        width: 40vw;

    }
    .header .menuContent{
        width: 88vw;
        margin-left: 2vw;
        margin-top: 2vh;

        padding: 3vw;

        display: none;

    }

    .header .menuContent h1{
        font-size: 8vw!important;
    }
    .header .menuContent h2{
        font-size:7vw!important;
        font-weight: 200;
    }
    .header .menuContent li{
        font-size: 5vw!important;
    }
    .header .menuContent h1:hover{
        font-size: 8vw!important;
    }




    /***************************
    *       displayNone        *
    ****************************/
    .menuButtons{
        display: none;
    }
}