/**************************************
*        CSS on all articles          *
***************************************/
.display__flex{
    width: 100%;
    display: flex;
    justify-content: center;
}

.tooltip_ref{
    font-size: 0.8vw!important;
    font-family: 'acumin-variable';
    /* font-style: italic; */
}

.middle__center{
    text-align: center;
}

.credit__margin{
    margin-left: 0.2vw;
}

.article__download__button{
    padding: 1vw 2vw;
    width: fit-content;
    background-color: black;
   
    border-radius: 20px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
}
.article__download__button:hover{
    padding: 1vw 2vw;
    border-radius: 20px;
    width: fit-content;
    background-color: red;
    border-color: red;

}
.article__download__button a{
    font-size: 1.3vw;
    color: white!important;
    font-family: 'EB Garamond', serif;
    font-style: italic;

}

.article__download__button a:hover{
    font-size: 1.3vw;
    color: white!important;
    font-family: 'EB Garamond', serif;
    font-style: italic;

}

.space__between{
    justify-content: space-between;

}

.flex__end{
    align-items: flex-end;
}

@media only screen and (max-width: 450px) {
    .article__download__button{
        padding: 2vw 3vw;
    }
    .article__download__button:hover{
        padding: 2vw 3vw;    
    }
    .article__download__button a{
        font-size: 4vw;
    }
    .article__download__button a:hover{
        font-size: 4vw;
    }

}