.archive{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
}


.archive__container__title{
    z-index: -1!important;
    margin-top: 1vh;
    margin-left: 40vw;
    position: absolute;

    font-size: 7vw;
    font-family: "adelphe";
    text-shadow: 0 0 6px yellow;

    /* background-color: blue; */

    -webkit-transform:scale(3.7,0.5); /*Safari and Chrome*/
    transform:scale(3.7,0.5);
}

.archive__container__content{
    position: absolute;
    width: 89vw;
    height: 85vh;

    margin-left: 7vw;
    padding-top: 15vh;
    overflow: scroll;


    display: flex;
    flex-wrap: wrap;

    /* flex-direction: column; */

    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.archive__content{
    width: fit-content;
    height: 20.9vw;
    padding: 1vw;
    margin-bottom: 3vw;
    /* z-index: 2; */

}

.archive__content h1{
    margin-bottom: 0.5vh;
    font-family: var(--font-ar-family-h1);
    font-size: var(--font-ar-size-h1);
}
.archive__content h2{
    font-style: italic;
    font-family: var(--font-ar-family-h2);
    font-size: var(--font-ar-size-h2);

    font-weight: 400;
}

.archive__content img{
    width: auto;
    height: 100%;
}


/***********************
*       renders        *
************************/
.archive__content__renders{
    display: flex;
    justify-content: center;
    align-items: center;
}

/***********************
*       opencall        *
************************/
.archive__content__opencall{
    display: flex;
    justify-content: center;
    align-items: center;
}

/**********************
*       agenda        *
***********************/
.archive__content__agenda img{
    width: auto;
    height: 100%;
    
    margin-left: auto;
    margin-right: auto;
    display: block;
}

/***********************
*       slider        *
************************/
.archive__content__slider img{
    margin-left: auto;
    margin-right: auto;
    display: block; 
}


/*********************
*       DATES        *
**********************/
.archive__content__archiveDate h4{
    font-style: italic;
    font-family: var(--font-ar-family-h4);
    font-size: var(--font-ar-size-h4);

    font-weight: 400;
}

.displayNone{
    display: none!important;
    visibility: hidden!important;
}



/*********************
*   Filter Button    *
**********************/
.filter__container{
    position: absolute;

    width: 15vw;
    right: 2vw;
    /* top: 18.3vh; */
    top: 10.3vh;

    /* z-index: 5!important; */

}
.filter__button{
    width: 2vw;
    padding: 0.4vw;

    text-align: center;
    /* background-color: aqua; */
    margin-left: 80%;

    border: 2px solid black;
    border-radius: 50px;
    border-width: 2px;


}
.filter__button h1{
    font-family: 'EB garamond';
    font-style: italic;
    font-weight: 500;
    font-size: 1.5vw;
}

.filter__wrapper{
    width: 86.7%;
    height: 50vh;

    margin-top: 0.5vh;
    padding: 1vw;
    z-index: 1;


    background-color: white;
    box-shadow: 0 0 5px gray;
    border-radius: 20px;

    display: flex;
    flex-direction: column!important;
    flex-wrap: wrap;

    display: none;

}
.filter__wrapper h1{
    font-family: 'EB garamond';
    font-style: italic;
    font-weight: 500;
    font-size: 1.5vw;
    
    color: lightgray;

}

.filter__wrapper button{
    width: 100%;

    margin-top: 1.6vh;
    padding: 0.1vh 0.3vw;

    color: white;
    background: lightgray;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}


@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/


    /****************************
    *          Changes          *
    ****************************/
    .archive__container__title{
        font-size: 6vw;
    }
    .archive__container__content{
        padding-top: 10vh;
    }
    .archive__content{
        width: 45%;    
    }

    .filter__container{
        width: 25vw;
        right: 2.3vw;
        top: 11vh;
    }
    .filter__button{
        width: 5vw;
        height: 5vw;
        padding: 0.4vw;

        text-align: center;
        margin-left: 80%;
        border-width: 1px;
    }
    .filter__button h1{
        font-size: 3vw;
    }

    .filter__wrapper{
        height: 30vh;
        padding: 2vw;
        border-radius: 7px;
    }
    .filter__wrapper h1{
        font-size: 4vw;
    }

    .filter__wrapper button{
        border-radius: 5px;
        font-size: 3vw;

    }
    
}