.singleInsitu{
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: relative;
}
/************************
*        VIDEO          *
*************************/
.singleinsitu__backgroundVideo{
    width: 100%;
    height: 100vh;
    overflow: hidden;

    position: relative;
}
.singleinsitu__backgroundVideo video{
    z-index: 1;
}
.singleinsitu__backgroundVideo__wrapper{
    width: 100%;
    height: 20%;

    margin-top: 40vh;

    position: absolute;
    color: white;
    /* mix-blend-mode: difference; */
    z-index: 4;

    display: flex;
    justify-content: center;

}
.singleinsitu__backgroundVideo__title{
    padding: 1vw;
}
.singleinsitu__backgroundVideo__title h1{
    font-size: var(--font-a-size-h1);
    font-family: var(--font-a-family-h1);

}
.singleinsitu__backgroundVideo__title h2{
    font-size: var(--font-a-size-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;

}

/************************
*       ARTICLE         *
*************************/
.singleInsitu br{
    display: block;
    margin: 0;
    line-height: 0;
}
.singleInsitu__container{
    position: relative;
    height: auto;
}
.singleInsitu__container__title{
    text-align: center;
    padding: 5vh 0;
}
.singleInsitu__container__title h1{
    color: black;
    font-size: var(--font-a-size-body-h1);
    font-family: var(--font-a-family-h1);

}
.singleInsitu__container__title h2{
    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-h2);
    font-style: italic;
    font-weight: 400;
}

.singleInsitu__container__intro{
    padding: 0 25vw 0 25vw;
    margin-top: 2vh;
    margin-bottom: 15vh;

}

.singleInsitu__container__intro > *{
    width: 50vw;

    margin-top: 3vh;

    font-size: var(--font-a-size-intro);
    font-family: var(--font-a-family-intro);
    font-style: italic;
    /* font-weight: 400; */
}



.singleInsitu__container__body{
    display: flex;
    height: fit-content;
}

.singleInsitu__container__body > *{
    width: 50%;
}
.singleInsitu__container__body strong{
    font-size: var(--font-a-size-strong);
}

.singleInsitu__body__text{
    height: fit-content;
    padding: 5vh 10vw 15vh 10vw;
    /* background-color: red; */
}

.singleInsitu__container h2{
    margin-top: 7vh;
    margin-bottom: 2vh;

    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-body-h2);
    font-weight: 400;
}
.singleInsitu__body__text h2:nth-child(1){
    margin-top: 0vh;
}
.singleInsitu__container h3{
    font-size: var(--font-a-size-body-h3);
    font-family: var(--font-a-family-body-h3);
    font-weight: 600;
    margin-bottom: 0.5vh;
    margin-top: 2.5vh;

}

.singleInsitu__body__text p{
    margin-bottom: 0.7vh;

    font-size: var(--font-a-size-p);
    font-family: var(--font-a-family-p);
}



.singleInsitu__body__image {
    height: auto;

    display: flex;
    justify-content: center;


}
.singleInsitu__body__image img{
    height: 70vh;

    padding-top: 7vh;
    padding-bottom: 15vh;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

}


.singleInsitu__body__text__middle{
    width: 100%;
    padding: 5vh 20vw 15vh 20vw;
}
.singleInsitu__body__text__middle h2{
    margin-top: 5vh;
    margin-bottom: 2vh;

    font-size: var(--font-a-size-body-h2);
    font-family: var(--font-a-family-body-h2);
    font-weight: 400;
}
.singleInsitu__body__text__middle h2:nth-child(1){
    margin-top: 0vh;
}

.singleInsitu__body__text__middle p{
    margin-bottom: 0.7vh;

    font-size: var(--font-a-size-p);
    font-family: var(--font-a-family-p);
}

.singleInsitu__container__none{
    display: none;
}

.singleInsitu__body__text__quote{
    width: 100%;
    padding: 5vh 10vw 15vh 10vw;
}
.singleInsitu__body__text__quote h2{
    font-size: var(--font-a-size-quote);
    font-family: var(--font-a-family-quote);
    font-style: italic;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.singleInsitu__body__text__pdf{
    width: 100%;

    /* background-color: red; */
    padding: 0 10vw;

    display: flex;
    justify-content: center;
}
.singleInsitu__body__text__pdf button{
    padding: 1vw;

    color: white;
    background-color: black;

    border-radius: 50px;
    border-style: solid;
    border-width: 2px;

    font-size: var(--font-a-size-pdf);
    font-family: var(--font-a-family-pdf);
    font-style: italic;

}
.singleInsitu__body__text__pdf button:hover{
    padding: 1vw;

    color: white;
    background-color: red;
    border-color: red;

    border-radius: 50px;
    border-style: solid;
    border-width: 2px;

    font-size: var(--font-a-size-pdf);
    font-family: var(--font-a-family-pdf);
    font-style: italic;

}

/************************
*        Slider         *
*************************/
.singleInsitu__container__slider{
    height: 60vh;
    padding: 0 5vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.singleInsitu__slider__image{
    height: 60vh;
}

.singleInsitu__container__slider .swiper-slide { 
    width: fit-content!important;
    height: 60vh;
    flex-shrink: 0;
}

.swiper-button-next::after{
    color: white;
    font-size: 3vh!important;
}
.swiper-button-next{
    color: white;
    font-size: 3vh!important;
}

.swiper-button-prev::after{
    color: white;
    font-size: 3vh!important;
}

.swiper-button-prev{
    color: white;
    font-size: 3vh!important;
}

/************************
*        MARKER         *
*************************/
.marker__container{
    position: fixed;

    width: 15vw;
    right: 2vw;
    top: 10.3vh;
    z-index: 1!important;

}
.marker__container svg .cls-1 {
    fill: #000000;
}
.marker__container svg .cls-2 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.marker__button{
    width: 3vw;
    margin-left: 80%;


}

.marker__wrapper{
    width: 86.7%;
    height: 50vh;

    margin-top: 0.5vh;
    padding: 1vw;
    z-index: 1;


    background-color: white;
    box-shadow: 0 0 5px gray;
    border-radius: 20px;

    display: flex;
    flex-direction: column!important;
    flex-wrap: wrap;

    display: none;

}
.marker__wrapper h1{
    font-family: 'EB garamond';
    font-style: italic;
    font-weight: 500;
    font-size: 1.5vw;
    
    color: lightgray;

}

.marker__wrapper button{
    width: 100%;

    margin-top: 1.6vh;
    padding: 0.1vh 0.3vw;

    color: white;
    background: lightgray;

    border: 2px solid lightgray;
    border-radius: 7px;

    font-family: var(--font-h-family-h3);
    font-size: var(--font-h-size-h3);
    font-weight: 400;

    cursor: crosshair;
}
.js-paint{
    z-index: -1!important;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
}

.transparentImg{
    z-index: -1!important;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: transparent;

}
.singleInsitu blockquote{
    font-size: 1vw;

}

@media only screen and (max-width: 450px) {
    /****************************
    *        displayNone        *
    ****************************/
    .marker__container, .singleinsitu__backgroundVideo{
        display: none;
    }

    /****************************
    *          Changes          *
    ****************************/
    .singleInsitu{
        width: 103%;
    }
    .singleInsitu__container__title{
        margin-top: 7vh;
        margin-left: 6vw;
        padding: 0;
        text-align: center;
        width: fit-content;
    }
    .singleInsitu__container__title h1{
        font-size: 8vw;
    }
    .singleInsitu__container__title h2{
        font-size: 6vw!important;
        font-family: "EB Garamond"!important;
        margin-top: 0!important;
    }

    .singleInsitu__container__intro{
        padding: 0 6vw;
        margin-bottom: 15vh;
    }

    .singleInsitu__container__intro > *{
        width: 100%;
        font-size: 4vw;
    }

    /* .singleInsitu__container__body > *{
        width: 50%;
        background-color: antiquewhite;
    } */
    .singleInsitu__body__text{
        width: 60%;
    }
    .singleInsitu__body__image{
        width: 40%;
    }
    .singleInsitu__container__body strong{
        font-size: var(--font-a-size-strong);
    }

    .singleInsitu__body__text{
        height: fit-content;
        padding: 5vh 6vw 15vh 6vw;
        /* background-color: red; */
    }

    .singleInsitu__container h2{
        margin-top: 7vh;
        margin-bottom: 2vh;

        font-size: 6vw;
    }
    .singleInsitu__body__text h2:nth-child(1){
        margin-top: 0vh;
    }
    .singleInsitu__container h3{
        font-size: 5vw;
    }
    .singleInsitu__body__text p{
        margin-bottom: 0.7vh;

        font-size: 4vw;
    }
    .singleInsitu__body__image img{
        height: 25vh;

    }

    .singleInsitu blockquote{
        font-size: 2.5vw;
 
    }
    .singleInsitu__body__text__middle{
        width: 100%;
        padding: 5vh 15vw 15vh 15vw;
    }
    .singleInsitu__body__text__middle h2{
        font-size: 4vw;
    }
    .singleInsitu__body__text__middle p{
        margin-bottom: 0.7vh;

        font-size: 4vw;
    }

    .singleInsitu__container__none{
        display: none;
    }

    .singleInsitu__body__text__quote{
        width: 100%;
        padding: 5vh 10vw 15vh 10vw;
    }
    .singleInsitu__body__text__quote h2{
        font-size: var(--font-a-size-quote);
        font-family: var(--font-a-family-quote);
        font-style: italic;
        font-weight: 400;
        text-align: center;
        width: 100%;
    }

    .singleInsitu__body__text__pdf{
        width: 100%;

        /* background-color: red; */
        padding: 0 10vw;

        display: flex;
        justify-content: center;
    }
    .singleInsitu__body__text__pdf button{
        padding: 1vw;

        color: white;
        background-color: black;

        border-radius: 50px;
        border-style: solid;
        border-width: 2px;

        font-size: var(--font-a-size-pdf);
        font-family: var(--font-a-family-pdf);
        font-style: italic;

    }
    .singleInsitu__body__text__pdf button:hover{
        padding: 1vw;

        color: white;
        background-color: red;
        border-color: red;

        border-radius: 50px;
        border-style: solid;
        border-width: 2px;

        font-size: var(--font-a-size-pdf);
        font-family: var(--font-a-family-pdf);
        font-style: italic;

    }


}



