.contributers{
    width: 100%;

}

.contributers .bg{
    width: 102%;
    height: 104%;

    margin-left: -1%;
    margin-top: -1%;
    position: fixed;

    filter: blur(8px);
    -webkit-filter: blur(8px);

}

.contributer__wrapper{
    width: 90%;
    padding: 0 3vw 0 7Vw;
    padding-bottom: 10vh;
    height: 101.5vh;
    /* margin-left: 7vw; */

    position: absolute;
    /* display: flex; */

    /* background-color: blue; */

    color: white;

    font-size: 5vw;

    overflow: scroll;

}

.contributer__wrapper ul{ 
    display: block;
}

.contributer__wrapper li{ 
    width: fit-content;
    list-style: none;
    display: inline-block!important;
    text-align: -webkit-match-parent;


    /* color: white; */

    text-decoration: none;
    margin-right: 1vw;
}

.contributer__wrapper li:hover{ 
    width: fit-content;
    list-style: none;
    display: inline-block;
    text-align: -webkit-match-parent;


    color: red;

    text-decoration: none;
    margin-right: 1vw;

}

.contributer__wrapper__fonts .contributer__wrapper__name{
    color: white;
}

.contributer__wrapper__fonts .contributer__wrapper__name:hover{
    color: red;
}

.contributer__wrapper__cards{
    position: relative;
}
.contributer__wrapper__cards .contributer__wrapper__projects{

    position: absolute;
    background-color: white;
    width: 20vw;

    padding: 2vw;
    display: none;
    flex-wrap: wrap;

    z-index: 3;
}
.contributer__wrapper__cards:hover{
    z-index: 3;
    /* height: 25vh; */

    /* background-color: green; */
}
.contributer__wrapper__cards:hover .contributer__wrapper__projects{
    width: 20vw;

    /* margin-left: 3vw; */
    margin-top: 2vh;
    padding: 1vw;

    background-color: white;
    box-shadow: 0 0 9px 10px white;
  

    position: absolute;

    display: flex;
    flex-wrap: wrap;

    z-index: 3;
}
.contributer__wrapper__projects h1{
    width: 100%;
    font-weight: 400;
    font-size: 2.5vw;
    margin-right: 1vw;
    margin-bottom: 1vh;
 
}
.contributer__wrapper__projects h2{
    font-weight: 400;
    font-size: 1.2vw;
    margin-right: 1vw;
    display: block!important;
    /* color: black!important; */
}

.contributer__wrapper__projects .null{
    color: black!important;
    pointer-events: none;
    text-decoration: none;
}
.contributer__wrapper__projects .null:hover{
    color: black!important;
    pointer-events: none;
    text-decoration: none;
}
.contributer__wrapper__projects a h2{
    font-family: var(--font-c-family-link)!important;
    font-weight: 400!important;
    font-size: 1.2vw;
    display: block!important;

}
.contributer__wrapper__projects a:hover h2{
    font-family: var(--font-c-family-link)!important;
    font-weight: 600!important;
    font-size: 1.2vw;
    display: block!important;
}

.contributer__wrapper__projects h2:last-of-type{
    display: none;
}


@media only screen and (max-width: 450px) {

    /***************************
    *       displayNone        *
    ****************************/
    .home__container, .home__website__info, .home__desktop{
        display: none;
    }

    /***************************
    *       changes        *
    ****************************/
    .contributer__wrapper{
        width: 93%;
        padding: 5vh 0 0 7Vw;    
        font-size: 12vw;    
    }

    .contributer__wrapper__cards:hover .contributer__wrapper__projects{
        width: 80vw;
    }
    .contributer__wrapper__projects h1{
        font-size: 5vw;
    }
    .contributer__wrapper__projects h2{
        font-size: 3.5vw!important;
    }
}



